@import-normalize;
body {
  margin: 0;
  // overflow: hidden;
  overflow-x: hidden;
  h1 {
    font-family: 'Xolonium Regular';
    font-size: 50px;
    color: white;
    margin: 0;
    text-transform: uppercase;
    text-align: center
  }
  h2 {
    font-family: 'Resist Sans Display';
    text-transform: uppercase;
  }
  h4 {
    font-family: 'Resist Sans Display';
    font-weight: 300;
    font-style: normal;
    font-size: 1.5em;
  }
  a {
    // font-family: 'Bully';
    // font-family: 'Robo';
    // font-family: 'Xolonium Regular';
    font-family: 'Xolonium Regular';
  }
  p {
    font-family: 'Resist Sans Display';
    font-weight: 500;
    font-style: normal;
    font-size: 1.1em;
  }
  @media screen and (max-width: 800px) {
    h1 {
      font-size: 36px;
    }
  }

  .container {
    // width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 860px;
  }
}

@font-face {
  font-family: 'Resist Sans Display Light Oblique';
  src: url('./fonts/font/ResistSansDisplay-LightOblique.eot');
  src: local('Resist Sans Display Light Oblique'), local('ResistSansDisplay-LightOblique'),
      url('./fonts/font/ResistSansDisplay-LightOblique.eot?#iefix') format('embedded-opentype'),
      url('./fonts/font/ResistSansDisplay-LightOblique.woff2') format('woff2'),
      url('./fonts/font/ResistSansDisplay-LightOblique.woff') format('woff'),
      url('./fonts/font/ResistSansDisplay-LightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Resist Sans Display';
  src: url('./fonts/font/ResistSansDisplay-Medium.eot');
  src: local('Resist Sans Display Medium'), local('ResistSansDisplay-Medium'),
      url('./fonts/font/ResistSansDisplay-Medium.eot?#iefix') format('embedded-opentype'),
      url('./fonts/font/ResistSansDisplay-Medium.woff2') format('woff2'),
      url('./fonts/font/ResistSansDisplay-Medium.woff') format('woff'),
      url('./fonts/font/ResistSansDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Resist Sans Display';
  src: url('./fonts/font/ResistSansDisplay-Bold.eot');
  src: local('Resist Sans Display Bold'), local('ResistSansDisplay-Bold'),
      url('./fonts/font/ResistSansDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/font/ResistSansDisplay-Bold.woff2') format('woff2'),
      url('./fonts/font/ResistSansDisplay-Bold.woff') format('woff'),
      url('./fonts/font/ResistSansDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Resist Sans Display';
  src: url('./fonts/font/ResistSansDisplay-Light.eot');
  src: local('Resist Sans Display Light'), local('ResistSansDisplay-Light'),
      url('./fonts/font/ResistSansDisplay-Light.eot?#iefix') format('embedded-opentype'),
      url('./fonts/font/ResistSansDisplay-Light.woff2') format('woff2'),
      url('./fonts/font/ResistSansDisplay-Light.woff') format('woff'),
      url('./fonts/font/ResistSansDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bully';
  src: url('./fonts/bully/Bully.otf');
}

@font-face {
  font-family: 'Robo';
  src: url('./fonts/robo/robo.ttf');
}

@font-face {
  font-family: 'Xolonium Regular';
  src: url('./fonts/xolonium/Xolonium-Regular.otf');
}

@font-face {
  font-family: 'Xolonium Bold';
  src: url('./fonts/xolonium/Xolonium-Bold.otf');
}
