.projects {
  text-align: center;
  // margin-top: 60px;
  padding-bottom: 80px;
  color: #d2d2d2;
  p {
    margin: 40px 0;
  }
  ul {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: 'Resist Sans Display';
    font-weight: 500;
    font-style: normal;
  }
}