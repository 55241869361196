.sponsors {
  // height: 100%;
  background-color: #100F10;
  color: rgb(210, 210, 210);
  padding: 12px;
  .main-sponsor {
    text-align: center;
    padding-bottom: 40px;
    // img {
    //   max-height: 160px;
    //   cursor: pointer;
    //   filter: greyscale(1);
    // }
    // img:hover {
    //   filter: greyscale(0);
    // }
  }
  .another-sponsors {
    text-align: center;
    .sponsors-img {
      display: inline-flex;
      flex-direction: row;
      // position: relative;
      // left: 50%;
      gap: 200px;
      // margin-right: 120px;
      margin-right: 10%;
      margin-bottom: 40px;
      // padding-left: 20px;
      // margin-left: 20px;
      // img {
      //   max-height: 160px;
      //   cursor: pointer;
      //   filter: grayscale(1);
      // }
      // img:hover {
      //   filter: greyscale(0);
      // }
      @media screen and (max-width: 760px) {
        flex-direction: column;
        gap: 20px;
        margin-right: 0;
      }
    }
  }
  h2 {
    margin-bottom: 32px;
  }
  img {
    max-height: 160px;
    cursor: pointer;
    filter: grayscale(100%);
    transition: 0.5s;
    object-fit: contain;
    max-width: 95%;
  }
  img:hover {
    filter: grayscale(0);
  }
}
