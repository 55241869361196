.company {
  text-align: center;
  margin-top: 60px;
  padding-bottom: 80px;
  color: #d2d2d2;
  img{
    height: 180px;
    margin-bottom: 40px;
  }
  h4 {
    margin-top: 40px;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  p {
    margin-top: 40px;
  }
}