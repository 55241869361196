.intro {
	background-size: cover;
	height: 100vh;
	background-repeat: no-repeat;
	background-position: center;
	.logo-large {
		height: 100vh;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-direction: column;
		gap: 35%;
		img {
			max-width: 300px;
			// max-width: 10%;
			max-height: 20%;
			object-fit: contain;
		}
		a {
			position: relative;
			height: 40px;
			font-size: 40px;
			line-height: 40px;
			padding: 20px 24px;
			background-color: rgb(252,0,255);
			color: rgb(255, 255, 255);
			cursor: pointer;
			transition: 0.5s;
		}
		a:hover {
			background-color: rgb(153, 18, 156);
		}
	}
}



