body {
  margin: 0;
  padding: 0;
  background: #100F10;
  font-family: sans-serif;
  position: relative;
  color: rgb(255, 255, 255);
}

.wrapper {
  min-height: 100vh;
  margin: 0 auto;
  // background: #a81212;
}
