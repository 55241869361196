.footer {
  background:#000;
  padding: 30px 0px;
  font-family: 'Play', sans-serif;
  text-align: center;
  .row {
    width: 100%;
    margin: 1% 0%;
    padding: 0.6% 0%;
    color:gray;
    font-size: 0.8em;
    font-family: 'Xolonium Regular';
    a {
      text-decoration: none;
      color:gray;
      transition: 0.5s;
      cursor: pointer;
      i {
        font-size: 2em;
        margin: 0% 1%;
      }
    }
    a:hover {
      color: #fff;
    }
    ul {
      width: 100%;
      padding: 0;
      li {
        display: inline-block;
        margin: 0px 30px;
      }
    }
  }
  @media (max-width:600px) {
    text-align: left;
    padding: 5%;
    .row ul li {
      display: block;
      margin: 12px 0px;
      text-align: left;
    }
    .row a i {
      margin: 0% 3%;
    }
  }
}