.mouse-track {
  // height: 680px;
  overflow-x: auto;
  overflow-x: hidden;
  // position: relative;
  padding: 60px 0;
  .image-track {
    display: inline-flex;
    flex-direction: row;
    position: relative;
    left: 50%;
    // transform: translateX(-100%);
    // gap: 40px;
    .image {
      object-fit: cover;
      object-position: 100% center;
    }
  }
}

.span {
  display: block;
  width: 1px;
  height: 5px;
  position: relative;
  left: 50%;
  background-color: red;
}
