// @import '../../css/helpers';

.image-box {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 0;
}

.image-bg {
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.image-behind {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  min-height: 500px;
  object-fit: cover;
}

.image-content {
  display: flex;
  align-items: center;
  justify-content: center;
  
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
