.navbar {
	position: fixed;
	z-index: 30;
	right: 0;
	left: 0;
	background: #000;
	// min-height: 65px;
	padding: 10px 0;

	.row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 12px;
		height: 100%;
	}

	.brand {
		// padding: 10px 20px;
		cursor: pointer;
		// padding: 0 12px;
		margin-bottom: auto;
		img {
			max-width: 100%;
			max-height: 40px;
			height: auto;
			vertical-align: middle;
		}
	}

	.menu-right {
		list-style: none;
		.menu {
			display: flex;
			height: 100%;
			gap: 28px;
		}
		.active {
			color: rgb(252,0,255) !important;
		}
		a {
			// padding: 20px 15px;
			text-align: center;
			font-size: 22px;
			text-decoration: none;
			cursor: pointer;
			color: rgb(153, 18, 156);
			text-transform: uppercase;
			transition: 0.5s;
		}
		a:hover {
			color: rgb(252,0,255);
		}

		.burger-menu {
			display: none;
			font-size: 40px;
			margin: 0px;
			padding: 0px;
			border: none;
			background: transparent;
			cursor: pointer;
			color: rgb(153, 18, 156);
			margin-left: auto;
		}
		.burger-menu:hover {
			color: rgb(252,0,255);
		}
		
		@media screen and (max-width: 1060px) {
			// min-width: 10em;
			min-width: 50%; //Не знаю
			.burger-menu {
				display: block;
			}
			.menu {
				// display: none;
				flex-direction: column;
				margin: 12px 0;
				text-align: end;
				a {
					text-align: end;
					// width: auto;
					// text-align: right;
					max-width: min-content;
					margin-left: auto;
				}
			}

			// .menu {
			// 	flex-direction: column;
			// }
			// button {
			// 	display: block;
			// 	margin-left: auto;
			// }
			// .menu > a {
			// 	display: none;
			// 	margin-left: auto;
			// }
		}
		@media screen and (min-width: 1060px) {
			.menu {
				display: flex !important;
			}
		}
	}
}






